const userState = {
    authenticated: false,
    theme: {},
    user: {
        language: null,
        fullName: '',
        username: '',
        branch: '',
        roles: [],
        localizationNameSpace: null,
        products: [],
        isExportButtonEnabled: false,
        enableEmail: false,
        enableSms: false,
        enableProfile: false,
        region: '',
        organizationName: ''
    },
    sidIdentifier: ''
}


export const home = {
    namespaced: true,
    state: userState,
    actions: {
        setUserState ({ commit }, data) {
            commit('setUserState', data)
        },
        setSidIdentifier ({ commit }, sidIdentifier) {
            commit('setSidIdentifier', sidIdentifier)
        }
    },
    mutations: {
        setUserState (state, data) {
            state.authenticated = data.authenticated
            state.user.language = data.language
            state.user.fullName = data.fullName
            state.user.username = data.username
            state.user.branch = data.branch
            state.user.roles = data.roles
            state.user.products = data.products
            state.user.isExportButtonEnabled = data.isExportButtonEnabled
            state.theme = data.theme
            state.user.enableEmail = data.enableEmail
            state.user.enableSms = data.enableSms
            state.user.region = data.region
            state.user.organizationName = data.organizationName
        },
        setSidIdentifier (state, setSidIdentifier) {
            state.sidIdentifier = setSidIdentifier
        }
    },
    getters: {
        sidIdentifier: (state) => {
            return state.sidIdentifier
        },
        userFullname: (state) => {
            return state.user.fullName
        },
        userUsername: (state) => {
            return state.user.username
        },
        userBranch: (state) => {
            return state.user.branch
        },
        userRole: (state) => {
            return state.user.roles
        },
        userIsAuthenticated: (state) => {
            return state.authenticated
        },
        exportButton: (state) => {
            return state.user.isExportButtonEnabled
        },
        theme : (state) => {
            return state.theme
        },
        enableEmail: (state) => {
            return state.user.enableEmail
        },
        enableSms: (state) => {
            return state.user.enableSms
        },
        enableProfile: (state) => {
            return state.user.enableProfile
        },
        region: (state) => {
            return state.user.region
        },
        products: (state) => {
            return state.user.products
        },
        organizationName: (state) => {
            return state.user.organizationName
        }
    }
}
