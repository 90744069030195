import { axiosBase ,EventBus} from '../_helpers'

export default { getRequest, postRequest, fileDownloadPostRequest, postRequest403Unhandled }

function getRequest (url) {
  return axiosBase.get(url)
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
          EventBus.$emit('unauthorized')
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}

function postRequest (url, data) {
  return axiosBase.post(url, data)
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
          EventBus.$emit('unauthorized')
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}

function fileDownloadPostRequest (url, data) {
  return axiosBase.post(url, data, { responseType: 'blob' })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
          EventBus.$emit('unauthorized')
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}


function postRequest403Unhandled (url, data) {
    return axiosBase.post(url, data)
        .catch(function (error) {
            throw error
        })
        .then(function (response) {
            return response
        })
}