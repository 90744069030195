<template>
    <div>
        <v-app>
            <router-view></router-view>
        </v-app>
    </div>
</template>

<script>
import {preLoginService} from "./services";

export default {
  name: 'App',
    mounted() {
      let host = window.location.host
        preLoginService.init(host).then(response => {

            if (null !== response.dataResponse.theme){
                this.$vuetify.theme.themes.dark = response.dataResponse.theme
                this.$vuetify.theme.themes.light = response.dataResponse.theme
            }
        }).catch((error) => {
            console.log(error)
        })
    }
};
</script>
<style>
    .content {
        min-height: calc(100vh - 48px);
    }
</style>
