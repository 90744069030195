import baseService from './baseService'

export const preLoginService = {
    getLogoData,
    init
}

function getLogoData(client) {
    return baseService.postRequest('/api/preLogin/logoData', { url: client })
}

function init (client) {
    return baseService.postRequest('/api/preLogin/init', { url: client })
}
