import baseService from './baseService'

export const dataServices = {
    getDataForCustomers,
    getDataForFilters,
    getUserDropDowns,
    saveCustomer,
    editApplicationStatus,
    fetchExportHistory,
    scoreCustomer,
    getLearningResources,
    getChartData,
    getHomepageDropdown,
    uploadProfilePicture,
    getProfilePicture,
    getUserInfo,
    deliverToken,
    countryList
}

function getDataForCustomers (data) {
    return baseService.postRequest('/api/secure/result/customer', data)
}

function getDataForFilters(product) {
    return baseService.getRequest(`/api/secure/result/${product}/dropDownData`)
}

function getUserDropDowns(product) {
    return baseService.getRequest(`/api/secure/result/${product}/userDropDown`)
}

function saveCustomer(data) {
    return baseService.postRequest('/api/secure/result/saveCustomer', data)
}

function scoreCustomer(data) {
    return baseService.postRequest('/api/secure/result/scoreCustomer', data)
}

function fetchExportHistory () {
    return baseService.getRequest('/api/secure/result/exportHistory')
}

function getLearningResources () {
    return baseService.getRequest('/api/secure/result/learningResources')
}

function editApplicationStatus(data) {
    return baseService.postRequest('/api/secure/result/editApplicationStatus', data)
}
function getChartData(data) {
    return baseService.postRequest('/api/secure/result/chartData', data)
}

function getHomepageDropdown() {
    return baseService.getRequest('/api/secure/result/homePageDropdown')
}
function uploadProfilePicture(file) {
    let formData = new FormData();
    formData.append("resource_type", "image")
    formData.append("file", file)

    return baseService.postRequest('/api/secure/result/uploadProfilePicture', formData)
}
function getProfilePicture() {
    return baseService.getRequest('/api/secure/result/getProfilePicture')
}

function getUserInfo() {
    return baseService.getRequest('/api/secure/result/getUserInfo')
}

function deliverToken(data) {
    return baseService.postRequest('/api/secure/token/delivery', data)
}

function countryList() {
    return baseService.getRequest('/api/secure/countries/list')
}
