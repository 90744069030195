import Vue from 'vue'
import vuetify from './_vuetify'
import App from './App.vue'
import {i18n, router} from './_helpers'
import store from "./_store"
import VueCookies from 'vue-cookies'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {alpha_num, email, max, max_value, min, min_value, numeric, regex, required} from 'vee-validate/dist/rules'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'

extend('regex', {...regex})
extend('alpha_num', {...alpha_num})
extend('numeric', {...numeric})
extend('min_value', {...min_value})
extend('max_value', {...max_value})
extend('min', {...min})
extend('max', {...max})
extend('required', {...required})
extend('email', {...email})

export const mixin = {
    methods: {
        $nedasTranslate: function (key, values) {
            let nameSpace = this.$store.state.home.user.localizationNameSpace
            if (nameSpace && this.$t(`msg.${nameSpace}_${key}`) && this.$t(`msg.${nameSpace}_${key}`) !== `msg.${nameSpace}_${key}`) {
                return this.$t(`msg.${nameSpace}_${key}`.toString(), values)
            } else {
                return this.$t(`msg.${key}`.toString(), values)
            }
        },
        $translatedErrorMessages: function (errors, name, replacements) {
            if (errors && Object.keys(errors).length > 0) {
                for (let [key] of Object.entries(errors)) {
                    return this.$nedasTranslate(`${name}_error_${key}`.toString(), replacements)
                }
            }
        }
    }
}


Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
highchartsMore(Highcharts)
Vue.use(HighchartsVue)
Vue.mixin(mixin)

Vue.config.productionTip = false
Vue.use(VueCookies)
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
    components: {App},
    template: '<App/>'
}).$mount('#app')
