import baseService from './baseService'

export const homeService = {
    fetchHome,
    logout
}

function fetchHome() {
    return baseService.getRequest('/api/home/info')
}

function logout() {
    return baseService.getRequest('/api/secure/appLogout')
}