import axios from 'axios'
let rootUrl = process.env.VUE_APP_BACKEND_URL

export const axiosBase = axios.create({
    baseURL: rootUrl,
    withCredentials: true,
    headers: {
        'client-url-header': window.location.hostname
    }
})
