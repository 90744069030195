import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from '../lang/messages'

Vue.use(VueI18n)

const locale = 'en' // default locale
const loadedLanguages = []

export const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages
})

export const translationHelper = {
  loadLanguageAsync
}

function setI18nLanguage (lang) {
  i18n.locale = lang
  window.$cookies.set('nedas_lang', lang)
}

function loadLanguageAsync (lang) {
  lang = lang.toString().toLowerCase()
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs[lang])
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
