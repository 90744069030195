import { mdiFilter, mdiAccountGroupOutline, mdiMagnify, mdiDownload, mdiMinus, mdiAccount,
  mdiLock, mdiChartBar, mdiArchive, mdiChartPie, mdiCogs, mdiStopCircleOutline, mdiBellOutline,
  mdiFileDocument, mdiFolderOpen, mdiHome, mdiChevronRight, mdiAccountGroup, mdiWindowMinimize,
  mdiWindowClose, mdiCheckAll, mdiCheck, mdiAccountEditOutline, mdiMenu, mdiLogoutVariant, mdiFileDownloadOutline,
  mdiCancel, mdiArrowRightDropCircleOutline, mdiCalendar, mdiInformation } from '@mdi/js'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import store from "../_store";

Vue.use(Vuetify)

const theme = {
  primary: '#00a86b',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  error: '#FF0000',
}

// export function getTheme() {
//   if (store.state.home.theme.primary) {
//     var color = this.theme.themes.light.primary
//      this.theme.themes.light.primary = store.state.home.theme.primary
//   }
//   return
//   // return store.state.home.theme.primary ? store.state.home.theme.primary : '#9C27b0'
// }


const cherryPicIcons = {
  iconfont: ['mdiSvg'],
  values: {
    mdiFilter: mdiFilter,
    mdiAccountGroupOutline: mdiAccountGroupOutline,
    mdiDownload: mdiDownload,
    mdiMinus: mdiMinus,
    mdiMagnify: mdiMagnify,
    mdiAccount: mdiAccount,
    mdiLock: mdiLock,
    mdiChartBar: mdiChartBar,
    mdiArchive: mdiArchive,
    mdiChartPie: mdiChartPie,
    mdiCogs: mdiCogs,
    mdiStopCircleOutline: mdiStopCircleOutline,
    mdiBellOutline: mdiBellOutline,
    mdiFileDocument: mdiFileDocument,
    mdiFolderOpen: mdiFolderOpen,
    mdiHome: mdiHome,
    mdiChevronRight: mdiChevronRight,
    mdiAccountGroup: mdiAccountGroup,
    mdiWindowMinimize: mdiWindowMinimize,
    mdiWindowClose: mdiWindowClose,
    mdiCheckAll: mdiCheckAll,
    mdiCheck: mdiCheck,
    mdiAccountEditOutline: mdiAccountEditOutline,
    mdiMenu: mdiMenu,
    mdiLogoutVariant: mdiLogoutVariant,
    mdiFileDownloadOutline: mdiFileDownloadOutline,
    mdiCancel: mdiCancel,
    mdiArrowRightDropCircleOutline: mdiArrowRightDropCircleOutline,
    mdiCalendar: mdiCalendar,
    mdiInformation: mdiInformation
  }
}

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons : cherryPicIcons
})
